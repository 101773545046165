.sociality {
   margin-top: 30px;

   &__gallery {

     &-text {
      font-family: GothamPro, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #000000;
      margin: 26px 0 110px 0;
      width: calc((100% - 110px) / 2);
      box-sizing: border-box;
      white-space: pre-line;

      @media screen and (max-width: 699px) {
        margin: 21px 0;
        width: 100%;
      }
     }

    &-table {
      width: 100%;
      display: -webkit-box; /* Not needed if autoprefixing */
      display: -ms-flexbox; /* Not needed if autoprefixing */
      display: flex;/* gutter size offset */
      width: auto;
      justify-content: center;
    }

    &-columns {
      // width: calc((100% - 100px)/2)!important;

      &:nth-child(2) {
        position: relative;
      }

      @media screen and (max-width: 699px) {
        margin: 0;
      }

      & > div {
        position: relative;
        margin-bottom: 110px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 699px) {
          margin: 55px 0;
        }
      }

      & > div > .card {
        width: 100%;
        height: 100%;
      }

      &:nth-child(even) {
        margin-left: 55px; /* gutter size */

        @media screen and (max-width: 699px) {
          margin: 0;
        }
      }

      &:nth-child(odd) {
        margin-right: 55px; /* gutter size */

        @media screen and (max-width: 699px) {
          margin: 0;
        }
      }
    }
   }
   
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-family: Formular;
      font-size: 60px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 9.5px;
      color: #000;
      text-transform: uppercase;
      margin: 0 40px 0 0;

      @media screen and (max-width: 560px) {
        font-size: 21px;
        letter-spacing: 7.5px;
        margin: 0;
      }
    }

    &-sort {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: calc((100% - 110px) / 2);

      &-active {
        color: #0004ff; 
      }

      & > button {
        font-family: GothamPro, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        text-transform: uppercase;
        margin: 0 5px;

        &:hover {
          color: #0004ff;
        }
      }
    }
  }

  &__info {
    width: 50%;
    max-width: 540px;
    margin-bottom: 50px;
    color: #000000;
    font-family: GothamPro, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

@media screen and (max-width: 699px) {
  .sociality {

    &__nav {

      &-sort {
        display: none;
      }
    }

    &__info {
      width: 90%;
      max-width: 800px;
    }
  }
}

