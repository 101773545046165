.work {
  margin-top: 34px;
  padding-bottom: 100px;

  @media screen and (max-width: 699px) {
    padding-bottom: 50px;
    margin-top: 30px;
  }

  &__mainTitleContent {
    position: relative;
    height: 45vw;
    width: 100%;
  }

  &__preload {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: white;
    transition: all 0.4s ease;
  }

  &__mainVideo {
    border: none;
    outline: none;
  }

  &__image {
    width: 100%;
    height: 100%; 
    
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin: 86px 0 100px 0;
  }

  &__left, &__right {
    width: calc((100% - 100px) / 2);
  }

  &__left {
    margin: 70px 30px 0 0;
    font-family: GothamPro, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;

    @media screen and (max-width: 799px) {
      width: 100%;
      align-items: center;
      margin: 0!important; 
      font-size: 16px;
    }

    &-info {
      margin-bottom: 97px;

      @media screen and (max-width: 799px) {
        margin: 0;
      }
    }

    strong {
      font-family: GothamBold, sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      width: max-content;

      @media screen and (max-width: 799px) {
        font-size: 16px;
      }
    }

    &-mobtitle {
      display: none;
      text-align: center;
    }

    table {
      border: none;
      width: 100%;

      @media screen and (max-width: 799px) {
        width: 80%;
        height: max-content;
      }

      & > tbody {
        width: 100%;
      }

      & > tbody > tr {
        height: auto!important;
      }

      & > tbody > tr > td:nth-child(odd) {
        display: flex;
        align-items: flex-start;
        font-family: GothamPro, sans-serif;
        font-size: 18px!important;
        font-weight: 600;
        line-height: 24px;
        min-width: 140px;
        width: max-content!important;
        height: auto!important;
        margin-right: 10px;
        border: none;

        @media screen and (max-width: 799px) {
          font-size: 14px;
        }

        @media screen and (max-width: 576px ) {
          min-width: max-content;
        }
      }

      & > tbody > tr > td:nth-child(even) {
        font-family: GothamPro, sans-serif;
        font-size: 18px!important;
        font-weight: 400;
        line-height: 30px;
        border: none;
        width: max-content!important;
        height: auto!important;

        @media screen and (max-width: 799px) {
          font-size: 14px;
        }

        span {
          font-size: 18px!important;

          a {
            text-decoration: underline;
            transition: all .4s ease-out;
            -webkit-transition: all .4s ease-out;
            -moz-transition: all .4s ease-out;
            -ms-transition: all .4s ease-out;
            -o-transition: all .4s ease-out;
          
            &:hover {
              color: #1518ff;
            }
          }
        }
      }
    }
    
    &-button {
      width: 150px;

      &-mob {
        display: none;
        margin-top: 40px;
      }
    }
  }

  &__right {
    
    &-title {
      font-family: Formular;
      font-size: 36px;
      line-height: 34px;
      font-weight: 500;
      margin: 0 0 41px 0;
    }

    &-text {
      font-family: GothamPro, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      white-space: pre-line;
    }
  }

  &__also {
    margin: 0 auto 100px;
    text-align: center;

    @media screen and (max-width: 560px) {
      margin: 12px 0 16px;
      text-align: left;
    }
  }

  &__details {
    font-family: Formular;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin: 0 auto 50px;
    display: none;
  }
}

@media screen and (max-width: 799px) {
  .work {
    padding: 0;
    
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 21px 0 50px 0;

      &-invisible {
        display: none!important;
      }
    }

    &__left {

      &-mobtitle {
        display: block;
        font-family: Formular;
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
        margin: 30px 0 50px 0;
      }

      &-table {

        & > tr > td {
          width: 50%;
          font-size: 14px !important;
        }

        & > tr > td:nth-child(odd) {
          display: flex;
          align-items: flex-start;
          margin: 0;
          width: max-content;
        }
      }

      &-button {
        display: none;

        &-mob {
          display: block;
        }
      }

      &-mobiletitle {
        display: block;
      }
    }

    &__right {
      width: 100%;
      margin-top: 50px;
      
      &-text {
        margin: 0 auto;
        font-size: 16px;
        line-height: 1.5;
      }

      &-title {
        display: none;
      }
    }

    &__also {
      font-size: 16px;
    }

    &__details {
      display: block;
    }
  }
}

