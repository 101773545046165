.mainSociality {
  height: max-content;
  padding-bottom: 106px;
  position: relative; 

  @media screen and (max-width: 560px) {
    padding-bottom: 60px;
  }

  &__title {
    color: #000;
    font-family: Formular;
    font-size: 24px;
    letter-spacing: 5px;
    text-transform: uppercase; 

    @media screen and (max-width: 560px) {
      font-size: 16px;
    }
  }

  &__gallery { 
    position: relative;
    margin: 50px 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr)); 
    grid-template-rows: auto;
    grid-gap: 100px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 699px) {
      margin-bottom: 30px;
    }

    & > div > img {
      width: 100%;
      height: 100%;
      object-fit:contain;
    }

    &-button {
      display: flex;
      justify-content: flex-start;
      font-family: Formular;
      font-size: 24px;
      letter-spacing: 3.33px;
      text-transform: uppercase;
      z-index: 1;
      width: 150px;

      .decorateButton {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 699px) {
  .mainSociality {

    &__gallery {
      grid-template-columns: 1fr;
      grid-gap: 50px; 
    }

    .mainSociality__gallery-button {
      margin: 0 auto;
    }
  }
}
