.decorateButton {
  position: relative;
  width: max-content;
  min-width: 140px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  

  &:hover {
    cursor: pointer;
  }

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: visible;
  }
  
  &-button {
    font-family: Formular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
  }
}