.main {

  &__work {

    .massonry__title {
      margin-bottom: 50px;
      
      @media screen and (max-width: 560px) {
        font-size: 16px;
      }
    }
    
    .decorateButton {
      margin-bottom: 100px;

      @media screen and (max-width: 699px) {
        margin-bottom: 50px;
      }
    }
  }
}
