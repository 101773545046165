.message {
  margin-top: 0;
  height: 700px;

  @media screen and (max-width: 560px) {
    height: 550px;
  }

  .wrapper {
    position: relative;
    height: 100%;
    display: flex;
  }

  &__social {
    position: absolute;
    bottom: 68px;
    right: 0;
    padding-right: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 276px; 
    height: 21px;

    @media screen and (max-width: 699px) {
      display: none;
    }
    
    a {
      font-family: GothamPro, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: 0.89px;
      color: #000000;
      cursor: pointer;
      transition: all .4s ease;
      z-index: 2;

      &:hover {
        color: #1518ff;
      }
    }
  }

  &__info {
    left: 0;
    width: max-content; 
    margin: 178px 0 128px;
    z-index: 2;   

    @media screen and (max-width: 560px) {
      margin: 135px 0;
      min-height: 270px;
    }

    &-title {
      font-family: Formular, sans-serif;
      font-size: 67px;
      font-weight: 400;
      color:#221d00;
      letter-spacing: -1px;
      margin: 0;
      text-transform: uppercase;
      white-space: pre-line;
      width: 100%;
    }

    &-text {
      font-family: GothamPro, sans-serif;
      font-size: 21px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 37px;
      margin-top: 60px;

      p {
        margin: 0;
      }

      div {
        display: flex;
        align-items: center;
        overflow: hidden;
      }
    }
  }

  &__animation {
    position: absolute;
    width: 100%;
    max-width: 900px;
    height: 140%;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 1; 

    & > svg {
      position: absolute;
      height: 110%;
      width: 110%;
      left: 30%;
      overflow: visible;

      @media screen and (max-width: 799px) {
        width: 130%;
        left: 20%;
        height: auto; 
      }

      @media screen and (max-width: 560px) {
        width: 140%;
        left: 15%;
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .message {
    height: auto;
    min-height: 570px;

    &__info {
      width: 100%;
      top: 50px; 

      &-title {
        font-size: 50px;
      }

      &-text {
        margin-top: 30px;
      }
    }
  }
}