.card {
  position: relative;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__info {
    position: absolute; 
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: url('./hoverbackground.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    transition: all 0.5s;
    z-index: 1;

    @media screen and (max-width: 699px) { 
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    & > div {
      position: absolute;
      padding-right: 37px;
      left: 37px;
      bottom: 32px;
      color: #ffffff;
      font-family: Formular;

      @media screen and (max-width: 560px) {
        left: 20px;
        bottom: 21px;
        padding-right: 20px;
      }

      & > span {
        font-family: Formular;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        @media screen and (max-width: 560px) {
          font-size: 18px;
        }
      }

      & > p {
        margin: 8px 0 0 0;
        font-family: Formular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        @media screen and (max-width: 560px) {
          font-size: 14px;
          margin: 7px 0 0 0;
        }
      }
    }
  }

  &__pici {
    position: absolute;
    width: 120%;
    height: 120%;
    left: -10%;
    top: 0;
    overflow: visible;
    z-index: -1;

    & > svg {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  }

  &__Y {
    position: absolute;
    top: -180px;
    right: 0;
    left: 0;
    width: 110%;
    height: 150%;
    background-image: url('../MassonryGallery/img/26-Y.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    z-index: -1;
  }
}

@media screen and (max-width: 899) {
  #backgroundPici { 
    display: none; 
  }
}

@media screen and (max-width: 799px) {
  .card {

    &__Y {
      display: none;
    }
  }
}