.massonry {
  // padding-bottom: 70px;
  
  &__title {
    color: #000;
    font-size: 24px;
    letter-spacing: 5px;
    font-family: Formular;
    text-transform: uppercase;
  }

  &__gallery {
    position: relative;

    &-button-invisible {
      display: none;
    }

    &-table {
      width: 100%;
      margin-top: 20px;
      display: -webkit-box; /* Not needed if autoprefixing */
      display: -ms-flexbox; /* Not needed if autoprefixing */
      display: flex;/* gutter size offset */
      width: auto;
      justify-content: center;
    }

    &-columns {

      & > div {
        position: relative;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
      }

      & > div > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:nth-child(even) {
        margin-left: 50px; /* gutter size */
        padding-top: 100px;
      }

      &:nth-child(odd) {
        margin-right: 50px; /* gutter size */
      }
    }
  }
}

@media screen and (max-width: 699px) {
  .massonry {
    margin-bottom: 20px;

    &__gallery {

      &-columns {
        &:nth-child(even) {
          margin-left: 0; /* gutter size */
        }
  
        &:nth-child(odd) {
          margin-right: 0; /* gutter size */
        }

        & > div {
          margin-bottom: 54px;
        }
      }
    }
  }
}