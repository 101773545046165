.select-language {
  display: flex;
  align-items: center;

  .active {
    color: #b3b2ba!important;
    cursor: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .disabled {
    opacity: 0;
  }
  
  a {
    font-family: Formular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000; 
    cursor: pointer;

    &:nth-child(2) {
      margin-right: 10px;
    }
  }

  &__footer {
    a {
      opacity: 0;
    }
  }
}