.workGallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  @media screen and (max-width: 799px) {
    margin: 0 0 10px;
  }

  @media screen and (max-width: 699px) {
    flex-direction: column;
  }

  div {
    @media screen and (max-width: 699px) {
      margin: 0 0 50px;  
      width: 100%;
    }
  }

  &__large {
    height: auto; 
    width: 100%;
    margin-bottom: 100px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__short-left, &__short-right  {
    height: auto; 
    width: calc((100% - 100px) / 2);
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    color: #000000;
    font-family: GothamPro, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    // line-height: 32px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__short-right {
    margin-left: 50px;
    white-space: pre-line;
  }

  &__short-left {
    margin-right: 50px 
  }
}