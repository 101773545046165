.contact { 
  position: relative;
  margin-top: 0;
  padding: 25px 0 100px;
  overflow: hidden;

  @media screen and (max-width: 560px) {
    margin-top: 30px;
    padding: 0;
  }

  .wrapper {
    height: 100%;
  }

  &__title {
    font-family: Formular, sans-serif;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 12.5px;
    color: #000000;
    text-transform: uppercase;
    margin: 0;

    @media screen and (max-width: 560px) {
      font-size: 21px;
      letter-spacing: 7.5px;
    }
  }

  .message__animation {
    right: -15%;

    @media screen and (max-width: 1199px) {
      max-width: 100%;
    }

    svg {
      width: 115%; 
      left: 0;

      @media screen and (max-width: 1199px) {
        width: 150%; 
      }

      @media screen and (max-width: 560px) {
        width: 190%; 
      }
    }
  }

  &__info {

    &-title {
      font-family: Formular, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.9;
      letter-spacing: 4.38px;
      color: #000000;

      @media screen and (max-width: 560px) {
        font-size: 18px;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start;
      }

      table {
        margin-top: 70px;
        width: 50%;

        @media screen and (max-width: 1199px) {
          width: 100%;
        }
        
        @media screen and (max-width: 560px) {
          width: 100%; 
          margin-top: 30px;
        }
        
        tr {
          display: flex;
          min-height: 70px;
          margin-left: -3px;
          width: 100%;

          @media screen and (max-width: 560px) {
            flex-direction: column;
            margin-top: 20px;
          }
        }
  
        th {
          font-family: Formular, sans-serif;
          font-size: 16px;
          line-height: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #000000;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          min-width: 37.5%;
          text-align: left;
          margin-right: 20px;

          @media screen and (max-width: 1199px) {
            min-width: 20%;
          }

          @media screen and (max-width: 799px) {
            width: 160px;
          }

          @media screen and (max-width: 560px) {
            font-size: 14px
          }
        }
  
        td {
          font-family: Formular, sans-serif;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: 0.5px;
          color: #000000;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          z-index: 2;
          white-space: pre-line;

          @media screen and (max-width: 560px) {
            width: 100%;
            font-size: 16px;
          }
  
          a { 
            line-height: 1;transition: all .4s ease;

            &:hover {
              color: #1518ff;
            }
          }
        }
  
        .telephone {
          min-height: 104px;

          td {
            margin-top: -5px;
            
            @media screen and (max-width: 560px) {
              margin-top: 0;
            }
          }
  
           td a {
            line-height: 1.5;
            margin-bottom: 5px;
            transition: all .4s ease;

              &:hover {
                color: #1518ff;
              }
           }
        }
  
        .adress {
          min-height: 100px;

          td {
            margin-top: -7px;

            @media screen and (max-width: 560px) {
              margin-top: 0;
            }
          }
        }
  
        .social {

          @media screen and (max-width: 699px) {
            margin-top: 20px;
          }

          @media screen and (max-width: 560px) {
            margin: 20px 0;
          }
  
          td {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 699px) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              margin-top: 0;
              height: 100px;
            }

            @media screen and (max-width: 560px) {
              width: 100%; 
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
            }
  
            a {
              margin-right: 19px;
              transition: all .4s ease;

              &:last-child {
                margin: 0;
              }

              &:hover {
                color: #1518ff;
              }
            }
          }
        }
      }

      .qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%; 
        padding-left: 80px;

        @media screen and (max-width: 1199px) {
          margin: 80px 0 0 0;
          align-self: center;
          padding-left: 0;
        }

        @media screen and (max-width: 560px) {
          margin: 30px 0 40px 0;
          align-self: center;
        }

        img {
          z-index: 2;
          width: 240px;
        }

        span {
          font-family: Formular;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.9;
          letter-spacing: 4.8px;
          color: #000000;
          text-transform: uppercase;
          z-index: 2;
          white-space: nowrap;
        }

        span:first-child {
          margin: 25px 0 0px;
        }
      }
    } 
  }
}