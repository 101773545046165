body {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10%;

  @media screen and (max-width: 560px) {
    padding: 0 24px;
  }
}

h3 {
  font-family: Formular;
  font-size: 24px;
  letter-spacing: 4.38px;
  text-transform: uppercase;
  margin: 0;
}

input {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  color: black;
}

#root {
  position: relative;
}

#gtx-trans {
  display: none;
}

a {
  transition: all .4s ease;

  &:hover {
    color: #1518ff;
  }
}