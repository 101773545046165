.menu {
  position: fixed; 
  transform: translate(100%);
  right: 0;
  top: 104px;
  height: calc(100vh - 104px);
  visibility: hidden;  
  background-color: white;
  width: 200px;
  box-sizing: content-box;
  padding-top: 38px;
  padding-right: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  transition: all .4s ease, -webkit-transform .4s ease;

  @media screen and (max-width: 900px) {
    overflow-y: scroll;
  }

  &-scroll {
    top: 70px; 
    height: calc(100vh - 70px);
  }

  &__visible {
    visibility: visible;
    transform: translate(0);
  }

  &__front {
    display: none;
    position: absolute;
    top: 0;
    width: 100%; 
    height: 100%;
    background-color: transparent;
    z-index: 2;
  }

  &__social {
    display: none;
  }
  
  &__form {
  border-bottom: 2px solid rgba(151, 151, 151, .2);
  width: 200px;
  display: none;

    & > input {
      width: 180px;
      color: #000000;
      font-family: Formular;
      font-size: 14px;
      letter-spacing: 0.67px;
      line-height: 52px;

      &::placeholder {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 949px) {
  .menu {

    &__form {
      display: flex;
    }

    &__social {
      display: flex;
    }
  }
}

@media screen and (max-width: 560px) {
  .menu {
    padding-top: 20px;
    height: calc(100vh - 51px);
    width: 100%;
    top: 80px;
  }
}