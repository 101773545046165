.workspage {

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 62px;

    @media screen and (max-width: 560px) {
      margin: 0 0 21px;
    }

    &-title {
      font-family: Formular, sans-serif;
      font-size: 60px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 12.5px;
      color: #000000;
      text-transform: uppercase;
      margin: 0 50px 0 0;

      @media screen and (max-width: 560px) {
        font-size: 21px;
        letter-spacing: 7.5px;
      }
    }

    &-sort {
      width: calc((100% - 100px) / 2);
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      &-active {
        color: #0004ff; 
      }

      & > button { 
        font-family: GothamPro, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        text-transform: uppercase;
        margin: 5px;

        &:hover {
          color: #0004ff;
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {

  .workspage {

    &__nav {


      &-sort {
        display: none;
      }
    }
  }
}