.footer {
  position: relative;
  width: 100%;
  height: max-content;
  background-color: #000000;
  padding: 45px 0 45px;

  @media screen and (max-width: 560px) {
    padding: 49px 0 0;
  }

  .wrapper {
    @media screen and (max-width: 560px) {
      margin: 0;
      padding-bottom: 50px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .language {
      a {
        color: white;
      }
    }
  }

  &__title {
    position: relative;
    color: #ffffff;
    font-family: Formular;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size: 21px;
    letter-spacing: 4.38px;
    text-transform: uppercase;
    margin-right: 20px;
  }

  &__menu {
    width: 100%;
    height: max-content;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 490px;
    flex-wrap: nowrap;

    @media screen and (max-width: 999px) {
      position: relative;
      max-width: 100%;
    }

    @media screen and (max-width: 560px) {
      display: none;
    }

    .footer__nav {
      margin-bottom: 0;
      justify-content: space-between;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
    height: max-content;

    @media screen and (max-width: 999px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 560px) {
      margin-bottom: 0;
    }

    &-imageFace {
      width: 70%;
      position: absolute;
      bottom: 12px;
      right: 0;
      
      @media screen and (max-width: 999px) {
        position: static;
      }

      @media screen and (max-width: 560px) {
        margin-bottom: 40px;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-left {
      position: relative; 
      width: 370px;
      display: flex;
      justify-content: flex-start;
      margin-right: 50px;
      height: 305px;
      // min-width: 350px;
      padding-bottom: 15px;

      @media screen and (max-width: 999px) {
        justify-content: center;
        align-items: flex-end;
        padding: 0;
        margin: 21px 0 0 0;
        height: auto;
      }

      @media screen and (max-width: 560px) {
        align-items: flex-start;
        width: 100%;
        margin-right: 0;
        height: max-content;
        flex-direction: column-reverse;
      }
    }

    &-right {
      margin-top: 75px;
      width: 480px;
      max-width: 590px;

      @media screen and (max-width: 999px) {
        width: 100%;
        margin: 50px 0 0 0;
      }

      @media screen and (max-width: 560px) {
        flex-direction: column;
      }

      &-info {
        position: relative;
        display: flex;
        margin-bottom: 100px;

        @media screen and (max-width: 999px) {
          justify-content: center;
          margin-bottom: 50px;
        }

        @media screen and (max-width: 560px) {
          flex-direction: column;
        }
      }
    }

    &-name {
      align-self: flex-end;
      width: 150px;
      min-width: 150px;
      flex-wrap: wrap;
      color: #fff;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #ffffff;
      font-family: Formular;
      font-size: 16px;
      box-sizing: border-box;
      white-space: nowrap;
    }

    &-contacts, &-sendmail {
      justify-content: center;
      display: flex;
      flex-direction: column;
      color: #fff;
      font-family: GothamPro, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;

      & > span {
        font-weight: bold;
        white-space: nowrap;
      }
    }

    &-contacts {
      width: 150px;
      max-width: 150px;
      align-self: flex-start;
      margin-right: 73px;

      .second-span {
        margin-top: 23px; 
      }

      & > a {
        color: #fff;
        font-family: GothamPro, sans-serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        transition: all .4s ease;
        white-space: nowrap;

        &:hover {
          color: #1518ff;
        }
      }
    }

    &-sendmail {
      height: max-content;

      p {
        margin: 0;
        white-space: pre-line;
      }
    }
  }

  &__social {
    margin-top: 20px;

    .social__footer {
      margin: 0;

      a {
        transition: all .4s ease;
      }

      a:hover {
        color: #fff; 
        color: #1518ff;
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0.4;
    font-family: GothamPro, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

    a {
      font-family: GothamPro, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }

    span, a {
      display: flex;
      align-items: center;
      margin: 0 5px;
      text-align: center;

      img {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__nav {
      display: none;
    }

    &__title {
      font-size: 14px;
    }

    &__content {

      &-image {
        display: none;
      }

      &-name {
        align-self: flex-start;
      }

      &-contacts {
        margin: 0 0 39px 0;
      }

      &-right {
        flex-direction: column;
      }

      &-right {
        margin-top: 35px;
      }
    }

    &__social {
      display: block;
    }
  }
}