.loading {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 10;

  @media screen and (max-width: 560px) {
    height: 100vh
  }

  &__main {
    display: flex;
    width: 200px;
    height: 90px;
    border: 1px solid #221D00;
    
    @media screen and (max-width: 560px) {
      margin-top: -60px;
      width: 140px;
      height: 63px;
    }

    &-left, &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;

      div {
        width: 40px!important;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 560px) {
          width: 28px!important;
        }
      }

      span {
        width: 100%;
        height: 100%;
        font-family: GothamPro, sans-serif;
        font-size: 48px;
        text-align: center;

        @media screen and (max-width: 560px) {
            font-size: 33px;
        }
      }
    }

    &-left {
      border-right: 1px solid #221D00;
    }
  }
}
