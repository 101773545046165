.error {
  background-color: #fff;
  width: 100%; 
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @media screen and (max-width: 649px) {
      width: 65%;
    }

    @media screen and (max-width: 449px) {
      width: 90%;
    }
  }

  img {
    object-fit: contain;
    width: 100%; 
  }

  &__link {
    padding: 10px 30px;
    border-radius: 10px;
    font-family: Formular, sans-serif;
    font-weight: 600;
    font-size: 24px;
    background-color: #5B5B5B;
    color: white;
    box-sizing: border-box;
    border: 3px solid transparent;
    transition: all .5s ease;
    margin: 60px 0;
    text-transform: uppercase;

    @media screen and (max-width: 649px) {
      font-size: 18px;
      padding: 10px 20px;
    }

    &:hover {
      color: #5B5B5B;
      background-color: white;
      border: 3px solid #5B5B5B;
    }
  }
}