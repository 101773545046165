.about {
  margin-top: 30px;

  @media screen and (max-width: 560px) {
    padding-top: 0;
  }

  &__title {
    font-family: Formular, sans-serif;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 12.5px;
    color: #000000;
    text-transform: uppercase;
    margin: 0;

    @media screen and (max-width: 560px) {
      font-size: 21px;
      letter-spacing: 7.5px;
    }
  }

  &__content {
    margin-top: 45px;

    @media screen and (max-width: 560px) {
      margin-top: 21px;
    }

    &-yova, &-studio {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 799px) {
        flex-direction: column;
        align-items: center;
      }

      .content_photo {
        width: calc((100% - 130px) / 2);
        // height: 503px;
        margin-right: 130px;

        @media screen and (max-width: 799px) {
          margin-right: 0;
          width: 100%;
        }

        @media screen and (max-width: 560px) {
          width: 100%
        }
  
        img {
          width: 100%; 
          object-fit: cover;
        }
      }

      .content_text {
        padding-top: 25px;
        width: calc((100% - 130px) / 2);

        @media screen and (max-width: 799px) {
          padding-top: 33px;
          width: 100%;
        }

        @media screen and (max-width: 560px) {
          width: 100%;
          padding-top: 33px;
        }

        .quote {
          font-family: Formular;
          font-size: 21px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 40px;
          letter-spacing: 4.38px;
          color: #000000;
          text-transform: uppercase;

          @media screen and (max-width: 560px) {
            font-size: 14px;
            line-height: 27px;
            letter-spacing: 2.92px;
          }
        }

        p {

          &:first-child {
            margin-top: 0;
          }
        }
        
        .title {
          font-family: Formular;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 5px;
          color: #000000;
          text-transform: uppercase;

          @media screen and (max-width: 560px) {
            font-size: 16px;
            letter-spacing: 3.33px;
          }
        }

        .text {
          font-family: GothamPro, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: #000000;
          margin: 25px 0;

          @media screen and (max-width: 560px) {
            font-size: 14px;
            line-height: 22px;
            margin: 21px 0;
          }
        }

        .text_special {
          font-family: Formular;
          font-size: 21px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.9;
          letter-spacing: 4.38px;
          color: #000000;
          text-transform: uppercase;
          margin: -15px 0 0 2px;
          max-width: 390px;

          @media screen and (max-width: 560px) {
            font-size: 14px;
            line-height: 27px;
            letter-spacing: 2.92px;
            margin-top: 10px;
          }
        }
      }

      .content_text-left {
        padding: 0;

        @media screen and (max-width: 799px) {
          padding: 33px 0 0 0;
        }

        p {

          &:first-child {
            margin-bottom: 63px;
          }
        }
      }
    }

    &-studio {
      margin-top: 92px;
      margin-bottom: 72px;

      @media screen and (max-width: 799px) {
        flex-direction: column-reverse;
        margin-top: 60px;
      }

      @media screen and (max-width: 576px) {
        margin-bottom: 30px;
      }

      .content_text {
        width: calc((100% - 130px) / 2);

        @media screen and (max-width: 799px) {
          width: 100%;
        }

        @media screen and (max-width: 560px) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .title {
          margin-bottom: 10px;
        }
        
        .text_special {
          max-width: 100%;
          margin: -21px 0 75px 0;

          @media screen and (max-width: 560px) {
            order: 3;
            margin: 19px 0 0;
          }
        }

        .text_block {
          margin-top: 35px;

          @media screen and (max-width: 560px) {
            margin: 0;
          }

          .text {
            margin: 25px 0;

            @media screen and (max-width: 560px) {
              margin: 21px 0;
            }
          }
        }
      }

      .content_photo {
        width: calc((100% - 130px) / 2);
        margin: 0 0 0 130px;

        @media screen and (max-width: 799px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &__press {

    &-button {
      margin: 75px 0;
      width: 150px;

      @media screen and (max-width: 799px) {
        margin: 50px auto 80px;
      }

      .decorateButton {
        margin: 0;
      }
    }
  }
}


.awards {
  display: flex;
  flex-direction: column;

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 45px 50px 60px;
    height: max-content;

    @media screen and (max-width: 799px) {
      padding: 45px 4% 60px
    }

    @media screen and (max-width: 560px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow: hidden;
      padding: 20px 0;
      transition: all 1s ease;
      height: 570px;
    }

    &.open {
      height: auto;
    }

    &-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 205px;
      margin: 30px 25px;
      min-height: 150px;

      @media screen and (max-width: 560px) {
        margin: 20px 0;
      }

      .photo { 
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          width: 150px; 
          object-fit: contain;
        }
      }

      .title {
        text-align: center;
        font-family: GothamPro, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #b3b2ba;
        margin-top: 20px;
      }
    }
  }

  &__more {
    height: 80px;
    width: 200px;
    align-self: center;
    margin: -30px 0 20px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #000000;
    font-family: Formular;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    display: none;

    @media screen and (max-width: 560px) {
      display: block;
    }
  }
}